import dynamic from 'next/dynamic';
import { FC } from 'react';

import { PropsSection, PropsStrapi } from 'holded/components/Layout/MainSection/MainSectioType';
import { hasKey } from 'holded/lib/utils';
import { DynamicSectionType } from 'holded/models/DynamicTypes';

export const sectionComponents: DynamicSectionType = {
  'hero.hero1': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero1')) as FC,
  'hero.hero2': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero2')) as FC,
  'hero.hero3': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero3')) as FC,
  'hero.hero4': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero4')) as FC,
  'hero.hero5': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero5')) as FC,
  'hero.hero6': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero6')) as FC,
  'hero.hero7': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero7')) as FC,
  'hero.hero8': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero8')) as FC,
  'hero.hero9': dynamic(() => import('holded/modules/cms/ui/sections/hero/Hero9')) as FC,

  'banner.banner1': dynamic(() => import('holded/modules/cms/ui/sections/banner/Banner1')) as FC,
  'banner.banner-bf': dynamic(() => import('holded/modules/cms/ui/sections/banner/BannerBlackFriday')) as FC,

  'feature.feature1': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature1')) as FC,
  'feature.feature2': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature2')) as FC,
  'feature.feature3': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature3')) as FC,
  'feature.feature4': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature4')) as FC,
  'feature.feature5': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature5')) as FC,
  'feature.feature6': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature6')) as FC,
  'feature.feature7': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature7')) as FC,
  'feature.feature8': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature8')) as FC,
  'feature.feature9': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature9')) as FC,
  'feature.feature10': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature10')) as FC,
  'feature.feature11': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature11')) as FC,
  'feature.feature12': dynamic(() => import('holded/modules/cms/ui/sections/feature/Feature12')) as FC,
  'feature.carousel': dynamic(() => import('holded/modules/cms/ui/sections/feature/Carousel')) as FC,
  'feature.feature-pricing': dynamic(() => import('holded/modules/cms/ui/sections/feature/FeaturePricing')) as FC,
  'feature.feature-with-list': dynamic(() => import('holded/modules/cms/ui/sections/feature/FeatureWithList')) as FC,
  'feature.block': dynamic(() => import('holded/modules/cms/ui/sections/feature/FeatureBlock')) as FC,

  'cta.cta1': dynamic(() => import('holded/modules/cms/ui/sections/cta/CTA1')) as FC,
  'cta.cta2': dynamic(() => import('holded/modules/cms/ui/sections/cta/CTA2')) as FC,
  'cta.cta3': dynamic(() => import('holded/modules/cms/ui/sections/cta/CTA3')) as FC,
  'cta.cta4': dynamic(() => import('holded/modules/cms/ui/sections/cta/CTA4')) as FC,
  'cta.cta5': dynamic(() => import('holded/modules/cms/ui/sections/cta/CTA5')) as FC,
  'cta.cta6': dynamic(() => import('holded/modules/cms/ui/sections/cta/CTA6')) as FC,

  'pricing.pricing1': dynamic(() => import('holded/modules/cms/ui/sections/pricing/Pricing1')) as FC,
  'pricing.pricing2': dynamic(() => import('holded/modules/cms/ui/sections/pricing/Pricing2')) as FC,
  'pricing.pricing3': dynamic(() => import('holded/modules/cms/ui/sections/pricing/Pricing3')) as FC,
  'pricing.pricing4': dynamic(() => import('holded/modules/cms/ui/sections/pricing/Pricing4')) as FC,
  'pricing.pricing-gems': dynamic(() => import('holded/modules/cms/ui/sections/pricing/PricingGems')) as FC,

  'faq.faq1': dynamic(() => import('holded/modules/cms/ui/sections/faq/FAQ1')) as FC,
  'faq.faq2': dynamic(() => import('holded/modules/cms/ui/sections/faq/FAQ2')) as FC,

  'stats.stats1': dynamic(() => import('holded/modules/cms/ui/sections/stats/Stats1')) as FC,
  'stats.stats2': dynamic(() => import('holded/modules/cms/ui/sections/stats/Stats2')) as FC,
  'stats.stats3': dynamic(() => import('holded/modules/cms/ui/sections/stats/Stats3')) as FC,
  'stats.stats4': dynamic(() => import('holded/modules/cms/ui/sections/stats/Stats4')) as FC,
  'stats.stats5': dynamic(() => import('holded/modules/cms/ui/sections/stats/Stats5')) as FC,

  'testimonials.testimonials1': dynamic(
    () => import('holded/modules/cms/ui/sections/testimonials/Testimonials1'),
  ) as FC,
  'testimonials.testimonials2': dynamic(
    () => import('holded/modules/cms/ui/sections/testimonials/Testimonials2'),
  ) as FC,
  'testimonials.testimonials3': dynamic(
    () => import('holded/modules/cms/ui/sections/testimonials/Testimonials3'),
  ) as FC,
  'testimonials.testimonials4': dynamic(
    () => import('holded/modules/cms/ui/sections/testimonials/Testimonials4'),
  ) as FC,
  'testimonials.trustpilot-line': dynamic(
    () => import('holded/modules/cms/ui/sections/testimonials/TrustpilotLine'),
  ) as FC,
  'testimonials.trustpilot-line-v3': dynamic(
    () => import('holded/modules/cms/ui/sections/testimonials/TrustpilotLineV3'),
  ) as FC,
  'testimonials.testimonials5': dynamic(
    () => import('holded/modules/cms/ui/sections/testimonials/Testimonials5'),
  ) as FC,

  'content.content1': dynamic(() => import('holded/modules/cms/ui/sections/content/Content1')) as FC,
  'content.content2': dynamic(() => import('holded/modules/cms/ui/sections/content/Content2')) as FC,

  'logos.logos1': dynamic(() => import('holded/modules/cms/ui/sections/logos/Logos1')) as FC,
  'logos.logos5': dynamic(() => import('holded/modules/cms/ui/sections/logos/Logos5')) as FC,
  'logos.logos3': dynamic(() => import('holded/modules/cms/ui/sections/logos/Logos3')) as FC,
  'logos.logos4': dynamic(() => import('holded/modules/cms/ui/sections/logos/Logos4')) as FC,

  'header.header1': dynamic(() => import('holded/modules/cms/ui/sections/header/Header1')) as FC,
  'header.header2': dynamic(() => import('holded/modules/cms/ui/sections/header/Header2')) as FC,

  'contact.contact1': dynamic(() => import('holded/modules/cms/ui/sections/contact/Contact1')) as FC,
  'contact.contact3': dynamic(() => import('holded/modules/cms/ui/sections/contact/Contact3')) as FC,
  'contact.live-storm': dynamic(() => import('holded/modules/cms/ui/sections/livestorm/Livestorm')) as FC,
  'contact.contact5': dynamic(() => import('holded/modules/cms/ui/sections/contact/Contact5')) as FC,
  'contact.contact-link': dynamic(() => import('holded/modules/cms/ui/sections/contact/ContactLink')) as FC,

  'blog.blog1': dynamic(() => import('holded/modules/cms/ui/sections/blog/Blog1')) as FC,

  'newsletter.newsletter1': dynamic(() => import('holded/modules/cms/ui/sections/newsletter/NewsletterFooter')) as FC,

  'sitemap.sitemap': dynamic(() => import('holded/modules/cms/ui/sections/sitemap/Sitemap')) as FC,

  'integrations.integration-apps': dynamic(
    () => import('holded/modules/cms/ui/sections/integrations/IntegrationsList'),
  ) as FC,
  'integrations.integration': dynamic(() => {
    return import('holded/modules/cms/ui/sections/integrations/Integration');
  }) as FC,

  'webinar.webinars': dynamic(() => import('holded/modules/cms/ui/sections/webinars/WebinarList')) as FC,
  'webinar.webinar': dynamic(() => import('holded/modules/cms/ui/sections/webinars/Webinar')) as FC,
  'webinar.webinar-register': dynamic(() => import('holded/modules/cms/ui/sections/webinars/WebinarRegister')) as FC,

  'guide.guides': dynamic(() => import('holded/modules/cms/ui/sections/guides/GuideCollectionList')) as FC,
  'guide.guide-collection': dynamic(() => import('holded/modules/cms/ui/sections/guides/GuideCollection')) as FC,
  'guide.guide': dynamic(() => import('holded/modules/cms/ui/sections/guides/Guide')) as FC,
  'guide.single-guide': dynamic(() => import('holded/modules/cms/ui/sections/guides/SingleGuide')) as FC,

  'tv.holded-tv': dynamic(() => import('holded/modules/cms/ui/sections/holdedTV/HoldedTV')) as FC,

  'card.card-list': dynamic(() => import('holded/modules/cms/ui/sections/cardList/CardList1')) as FC,
  'card.card-list2': dynamic(() => import('holded/modules/cms/ui/sections/cardList/CardList2')) as FC,
  'card.card-list3': dynamic(() => import('holded/modules/cms/ui/sections/cardList/CardList3')) as FC,
  'card.card-list4': dynamic(() => import('holded/modules/cms/ui/sections/cardList/CardList4')) as FC,

  'image.image': dynamic(() => import('holded/modules/cms/ui/sections/image/ImageSection')) as FC,
  'image.image-full-width': dynamic(() => import('holded/modules/cms/ui/sections/image/ImageFullWidth')) as FC,

  'book-demo.book-demo': dynamic(() => import('holded/modules/cms/ui/sections/bookDemo/BookDemo')) as FC,

  'success-cases.success-cases-list': dynamic(
    () => import('holded/modules/cms/ui/sections/successCases/SuccessCasesList'),
  ) as FC,
  'success-cases.success-case': dynamic(() => import('holded/modules/cms/ui/sections/successCases/SuccessCase')) as FC,

  'spacing.spacing': dynamic(() => import('holded/modules/cms/ui/sections/spacing/Spacing')) as FC,

  'comparative-table.comparative-table1': dynamic(
    () => import('holded/modules/cms/ui/sections/comparativeTable/ComparativeTable1'),
  ) as FC,
  'comparative-table.ct3': dynamic(
    () => import('holded/modules/cms/ui/sections/comparativeTable/ComparativeTable3'),
  ) as FC,

  'store.holded-store': dynamic(() => import('holded/modules/cms/ui/sections/holdedStore/HoldedStore')) as FC,

  'forms.typeform': dynamic(() => import('holded/modules/cms/ui/sections/typeform/TypeformForm')) as FC,
  'forms.hubspot': dynamic(() => import('holded/modules/cms/ui/sections/hubspot/HubspotForm')) as FC,

  'partner.partner-card-list': dynamic(() => import('holded/modules/cms/ui/sections/partner/PartnerCardList')) as FC,
};

const Section = ({ sectionData, locale, pageData, globalData }: PropsSection) => {
  const SectionComponent = hasKey(sectionComponents, sectionData?.__component as keyof typeof sectionComponents)
    ? sectionComponents[sectionData.__component as keyof typeof sectionComponents]
    : null;

  if (!SectionComponent) {
    return null;
  }
  return <SectionComponent {...{ ...sectionData, ...{ locale, pageData, globalData } }} />;
};

const SectionsStrapi = ({ pageData, globalData }: PropsStrapi) => {
  return (
    <div className={'flex flex-col'}>
      {pageData?.attributes?.sections?.map((section) => {
        return (
          <Section
            key={`${section.__component}${section.id}`}
            sectionData={section}
            locale={pageData.attributes?.locale ?? 'en'}
            pageData={pageData}
            globalData={globalData}
          />
        );
      })}
    </div>
  );
};

export default SectionsStrapi;
