import { sectionComponents } from 'holded/components/Layout/MainSection/MainSection';
import { Page } from 'holded/modules/cms/domain/page';

export const camelize = (text: string, separator = '-') =>
  text
    ?.split(separator)
    .map((w) => w.replace(/./, (m) => m.toUpperCase()))
    .join('');

export const kebalize = (str: string) => {
  if (!str) return;
  const result = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
  if (!result) return;
  return result.join('-').toLowerCase();
};

export const cleanDoubleSlash = (str: string | undefined) => {
  if (!str) return '';
  const end = str.slice(-1) == '/' ? str.slice(0, -1) : str;
  return end.slice(0, 1) == '/' ? end.slice(1) : end;
};

export function hasKey<O extends object, K extends keyof O>(obj: O, key: K): key is K {
  return key in obj;
}

export const getCustomTag = (defaultTag: string, customTag: string | undefined) => {
  return customTag == 'default' || customTag == null || false
    ? (defaultTag as keyof JSX.IntrinsicElements)
    : (customTag as keyof JSX.IntrinsicElements);
};

export const getCustomTagNext = (tag: string) => {
  return `h${Number(tag.slice(-1)) + 1}` as keyof JSX.IntrinsicElements;
};

export const isDefault = (element: string | undefined) => {
  return element == 'default' || element == null || undefined;
};

export const showUnusedSections = (pages: [Page]) => {
  const allSections = pages
    .map((page) => {
      return page.attributes?.sections.map((component) => component.__component);
    })
    .flat();

  const sections = allSections.filter((item, i) => allSections.indexOf(item) == i).sort();

  return Object.keys(sectionComponents).reduce((acc: string[], section: string) => {
    if (!sections.includes(section)) acc.push(section);
    return acc;
  }, []);
};

export const formatAmountInNMinimumDigits = (amount: number, nrOfDigits: number) => {
  return new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: nrOfDigits,
  }).format(amount);
};
